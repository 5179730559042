import {toValue, valueType} from '../css'
import {isValue} from '../is'

const
    type = valueType,

    height = value => (
        isValue(value) ?
            {lineHeight: toValue(value)} :
            {})

export {height, type}
