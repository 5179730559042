import PropTypes from 'prop-types'
import {isNumber} from '@republic/foundation/lang/is'
import {isValue} from '../is'

const
    type = PropTypes.oneOfType([
        PropTypes.bool,
        PropTypes.number
    ]),

    flex = flexed => (
        isValue(flexed) ?
            {flex: isNumber(flexed) ? flexed : 1} :
            {})

export {flex, type}
