import PropTypes from 'prop-types'
import {isValue} from '../is'

const
    type = PropTypes.oneOf(['uppercase', 'lowercase', 'capitalize', false]),

    transform = value => (
        isValue(value) ?
            {textTransform: value} :
            {})

export {transform, type}
