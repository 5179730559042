import PropTypes from 'prop-types'
import {isString} from '@republic/foundation/lang/is'

const
    type = PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.oneOf([false])
    ]),

    color = value => (
        isString(value) ?
            {color: value} :
            {})

export {color, type}
