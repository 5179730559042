const
    // branding
    red = '#ED260E',
    orange = '#F75231',
    pink = '#FC0B6E',
    blue = '#0E6DED',
    teal = '#1DB1BA',
    green = '#0EBD15',
    yellow = '#E0CF02',
    darkTeal = '#11656A',

    // grayscale
    white = '#FFFFFF',
    light = '#BFC3C5',
    medium = '#A8ACAD',
    dark = '#2E3234',
    disabled = '#272B2D',
    background = '#24282A',
    twilight = '#1F2325',
    black = '#101415'

export default {
    white,
    light,
    medium,
    dark,
    disabled,
    twilight,
    black,
    red,
    orange,
    pink,
    blue,
    teal,
    darkTeal,
    green,
    yellow,
    background
}
