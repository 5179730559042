import PropTypes from 'prop-types'
import {isPlainObject, isString, isArray} from '@republic/foundation/lang/is'
import {spacingType, bordering} from '../css'
import {isValue} from '../is'

const
    STYLE = 'solid',
    COLOR = '#D4D4D4',
    WIDTH = 1,

    type = (
        PropTypes.oneOfType([
            PropTypes.bool,
            PropTypes.array,
            PropTypes.shape({
                color: spacingType,
                width: spacingType,
                style: spacingType
            }),
            PropTypes.string])),

    border = value => (
        isValue(value) ?
            isPlainObject(value) ?
                {
                    ...bordering(value.style || STYLE, 'style'),
                    ...bordering(value.width || WIDTH, 'width', 'px'),
                    ...bordering(value.color || COLOR, 'color')
                } :
                isString(value) ?
                    {border: value} :
                    {
                        borderStyle: STYLE,
                        borderColor: COLOR,
                        ...bordering(isArray(value) ? value : 1, 'width', 'px')
                    } :
            {})

export {border, type}
