import {Stream} from '@republic/foundation/streams'
import {each} from '@republic/foundation/lang/array'
import {isEqual} from '@republic/foundation/lang/is'
import {keys, owns} from '@republic/foundation/lang/object'
import {cleanup} from '../../core/services/cleanup'
import {authenticated, user, info} from '../../auth/streams'
import {subscriber, role} from '../../subscriber/streams'
import {initialize as amplitude} from './amplitude'
import cookie from './cookie'
import track from './track'
import identity from './identity'
import props from './props'

const
    listeners = {},

    // future analytic platforms wire in here
    initialize = () => {
        amplitude()

        Stream.combineAll([
            authenticated,
            user,
            info,
            role
        ])
        .subscribe(([authenticated, user, info, role]) => {
            const {amplitude} = cookie()

            if (authenticated && user) {
                if (!amplitude.userId || amplitude.userId !== user) {
                    identity(user)
                    props({
                        email: info.email,
                        role
                    })
                    if (!owns(listeners, 'subscriber')) {
                        listeners.subscriber = (
                            subscriber
                            .unique(isEqual)
                            .subscribe(subscriber_id => {
                                if (subscriber_id) {
                                    props({subscriber_id})
                                }
                            }))
                    }
                }
                track('authenticated')
            }
            else if (!authenticated) {
                identity(null)
            }
        })
    }

cleanup({
    name: 'analytics',
    fn: () => (
        each(
            keys(listeners),
            key => {
                listeners[key]()
                delete listeners[key]
            }))
})

export default initialize
