import {valueType} from '../css'
import {isValue} from '../is'

const
    type = valueType,

    opacity = value => (
        isValue(value) ?
            {opacity: value} :
            {})

export {opacity, type}
