import amplitude from 'amplitude-js'
import env from '../../env'

const
    analytics = amplitude.getInstance(),
    track = (event, props) => analytics.logEvent(event, props),
    identity = id => analytics.setUserId(id),
    props = props => analytics.setUserProperties(props),
    cookie = () => analytics.options,
    initialize = () => {
        analytics.init(env.analytics.amplitude, null, {disableCookies: true})
        analytics.setVersionName(env.version)
    }

export {
    analytics,
    cookie,
    initialize,
    track,
    identity,
    props
}
