import PropTypes from 'prop-types'
import {isString} from '@republic/foundation/lang/is'

const
    type = PropTypes.oneOf(['visible', 'hidden', 'scroll', 'auto']),

    overflow = value => (
        isString(value) ?
            {overflow: value} :
            {})

export {overflow, type}
