import PropTypes from 'prop-types'
import {isValue} from '../is'

const
    type = PropTypes.oneOf(['left', 'center', 'right', false]),

    align = aligned => (
        isValue(aligned) ?
            {textAlign: aligned} :
            {})

export {align, type}
