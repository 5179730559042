import semver from 'semver'
import {updated} from '@republic/foundation/http/query'
import {origin} from '@republic/foundation/http/urls'
import {createNamed} from '@republic/foundation/storage'
import {head} from '@dash/core/services/array'
import {getter} from '@dash/core/services/request'
import session from '@dash/core/services/storage/session'
import env from '@dash/env'

const
    cache = createNamed(session, `${env.version}:model:subscriber`),

    fetch = () => (
        Promise.all([
            getter(null, `${origin()}/version.json?${new Date().getTime()}`),
            Promise.resolve(cache.get()?.subscriber || '')
            .then(subscriber_id => (
                subscriber_id ?
                    getter(
                        null,
                        updated(
                            `/ota/upgrades`,
                            {
                                subscriber_id,
                                incremental_version: 1,
                                model: 'relay2',
                                os_build_id: 'HACK'
                            }))
                    .then(result => head(result)) :
                    Promise.resolve({})))
        ])
        .then(([dash, rom]) => ({
            current: env.version,
            next: dash.current,
            minimum: dash.minimum,
            rom: rom.upgrade_version || null,
            urgent: semver.lt(env.version, dash.minimum),
            update: semver.lt(env.version, dash.current)
        })))

export {fetch}