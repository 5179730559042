import PropTypes from 'prop-types'
import {isString} from '@republic/foundation/lang/is'

const
    type = PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string,
        PropTypes.oneOf([false])
    ]),

    layer = value => (
        value ?
            {zIndex: isString(value) ? parseInt(value, 10) : value} :
            {})

export {layer, type}
