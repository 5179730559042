import {toValue, valueType} from '../css'
import {isValue} from '../is'

const type = valueType,

    dimensions = (type, value) => (
        isValue(value) ?
            {[type]: toValue(value)} :
            {})

export {dimensions, type}
