import React from 'react'
import PropTypes from 'prop-types'
import {Layout, Type} from '@republic/bits'
import {createComponent} from '@dash/core/services/component'
import colors from '@dash/core/colors'
import SyncIcon from '@dash/core/components/SyncIcon'
import useThrottle from '@dash/core/hooks/throttle'

const
    sizes = {
        t: {
            font: 0.6,
            margin: 0.75,
            icon: 0.65,
            diameter: 1.5
        },
        s: {
            font: 0.8,
            margin: 0.75,
            icon: 0.85,
            diameter: 1.75
        },
        m: {
            font: 1,
            margin: 0.75,
            icon: 1,
            diameter: 2
        },
        l: {
            font: 1.2,
            margin: 0.75,
            icon: 1.25,
            diameter: 2.5
        },
        xl: {
            font: 1.4,
            margin: 1,
            icon: 1.25,
            diameter: 2.75
        }
    },

    radius = outline => (
        outline === 'round' ?
            100 :
            outline === 'center' ?
                0 :
                (outline === 'left' || outline === 'right') ?
                    [
                        outline === 'left' ? 10 : 0,
                        outline === 'right' ? 10 : 0,
                        outline === 'right' ? 10 : 0,
                        outline === 'left' ? 10 : 0
                    ] :
                    10)

export default (
    createComponent(
        'Button',
        {
            propTypes: {
                size: PropTypes.oneOf(['t', 's', 'm', 'l', 'xl']),
                outline: PropTypes.oneOf([true, false, 'round', 'left', 'right', 'center'])
            },
            defaultProps: {
                size: 's',
                background: colors.twilight,
                align: 'left',
                throttle: 250
            }
        },
        ({
            action,
            align,
            background,
            description,
            disabled,
            focused,
            Icon,
            label,
            nowrap,
            workbreak,
            outline,
            rotate,
            selected,
            size,
            throttle,
            unread,
            ...props
        }) => {
            const
                {onClick, throttled} = useThrottle(evt => action && !disabled ? action(evt) : false, action && throttle),
                disable = disabled || throttled,
                core = {
                    row: true,
                    radius: radius(outline),
                    align: 'center',
                    justify: 'center',
                    color: (
                        !outline ?
                            false :
                            !action ?
                                colors.background :
                                disable ?
                                    colors.dark :
                                    focused ?
                                        colors.darkTeal :
                                        selected ?
                                            colors.teal :
                                            background),
                    cursor: action ? disable ? 'not-allowed' : 'pointer' : 'normal',
                    onClick
                }

            return (
                !outline ?
                    <Layout
                        tag={'button'}
                        {...core}
                        padding={0}
                        color={'transparent'}
                        border={'none'}
                        style={{...(props.style || {}), textAlign: 'left'}}
                        justify={'start'}
                        {...props}>
                        {Icon &&
                            <Layout
                                position={'relative'}
                                style={{flexShrink: 0, userSelect: 'none'}}
                                color={focused ? colors.darkTeal : selected ? disable ? colors.darkTeal : colors.teal : background}
                                border={{color: !action || disable || selected ? colors.black : colors.teal, width: 1}}
                                width={sizes[size].diameter}
                                height={sizes[size].diameter}
                                radius={10}
                                align={'center'}
                                justify={'center'}
                                transition={'all 0.25s ease'}>
                                {unread &&
                                    <Layout
                                        position={'absolute'}
                                        positions={{top: -0.25, left: -0.25}}
                                        radius={1}>
                                        <Layout
                                            width={1}
                                            height={1}
                                            color={colors.pink}
                                            border={{color: colors.black, width: 1}}
                                            radius={1} />
                                    </Layout>}
                                <Layout className={(rotate || (throttled && !disabled)) ? 'rotate' : ''} opacity={disable ? 0.5 : 1}>
                                    {(throttled && !disabled) ?
                                        <SyncIcon width={sizes[size].icon} invert />:
                                        <Icon width={sizes[size].icon} invert={!(selected && !disable)} />}
                                </Layout>
                            </Layout>}
                        {(label || description) &&
                            <Layout margin={{left: Icon ? sizes[size].margin : 0}}>
                                {label &&
                                    <Type
                                        align={align}
                                        size={sizes[size].font - (description ? 0.2 : 0)}
                                        weight={'bold'}
                                        color={disable ? colors.medium : colors.white}
                                        style={nowrap ? {whiteSpace: 'nowrap'} : {}}>
                                        {label}
                                    </Type>}
                                {description &&
                                    <Type
                                        align={align}
                                        margin={{top: label ? 0.1 : 0}}
                                        size={sizes[size].font - 0.4}
                                        color={colors.medium}
                                        style={nowrap ? {whiteSpace: 'nowrap'} : workbreak ? {wordBreak: 'break-word'} : {}}>
                                        {description}
                                    </Type>}
                            </Layout>}
                    </Layout> :
                    <Layout
                        tag={'button'}
                        border={{
                            width: outline === 'left' ? 1 : outline === 'right' ? [1, 1, 1, 0] : outline === 'center' ? [1, 1, 1, 0] : 1,
                            color: action && !disable && !selected ? colors.teal : colors.black
                        }}
                        style={{...(props.style || {}), flexShrink: 0}}
                        padding={[0, outline === 'round' ? 0 : 1]}
                        minHeight={sizes[size].diameter + 0.25}
                        minWidth={sizes[size].diameter + 0.25}
                        {...core}
                        {...props}>
                        {Icon &&
                            <Layout className={rotate ? 'rotate' : ''} opacity={disable ? 0.6 : 1} style={{userSelect: 'none'}}>
                                <Icon width={sizes[size].icon} invert={!(selected && !disable)} />
                            </Layout>}
                        {label &&
                            <Type
                                align={'left'}
                                size={sizes[size].font}
                                style={{whiteSpace: 'nowrap'}}
                                margin={Icon ? {left: sizes[size].margin} : false}
                                color={selected ? colors.black : disabled ? colors.light : colors.white}>
                                {label}
                            </Type>}
                    </Layout>
            )
        }))
