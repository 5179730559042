import PropTypes from 'prop-types'
import {isValue} from '../is'

const
    alignments = {
        'start': 'flex-start',
        'center': 'center',
        'end': 'flex-end'
    },

    type = PropTypes.oneOf(['start', 'center', 'end']),

    align = aligned => (
        isValue(aligned) ?
            {alignItems: alignments[aligned]} :
            {})

export {align, type}
