import React from 'react'
import PropTypes from 'prop-types'
import {Layout} from '@republic/bits'
import {createComponent} from '@dash/core/services/component'

export default (
    createComponent(
        'Icon',
        {
            propTypes: {
                invert: PropTypes.bool,
                white: PropTypes.string.isRequired,
                black: PropTypes.string.isRequired
            },
            defaultProps: {
                width: 1.5
            }
        },
        ({invert, width, white, black, ...props}) => (
            <Layout width={width} {...props}>
                <img src={invert ? white : black} />
            </Layout>
        )))
