import PropTypes from 'prop-types'
import {isValue} from '../is'

const
    type = PropTypes.oneOf([true, false, 'static', 'relative', 'absolute', 'fixed', 'sticky']),

    position = value => (
        isValue(value) ?
            {position: value === true ? 'relative' : value} :
            {})

export {position, type}
