import {reduce} from '@republic/foundation/lang/array'
import backoff from '@dash/core/services/backoff'

const
    isPromise = promise => (
        !!promise && typeof promise.then === 'function'),

    series = fns => (
        reduce(
            fns,
            (promise, fn) => (
                promise.then(results => (
                    Promise.resolve(fn())
                    .then(
                        result => {
                            results.push(result)
                            return results
                        },
                        reason => Promise.reject(reason))))),
            Promise.resolve([]))),

    delay = (timeout, passthrough) => (
        new Promise(resolve => (
            setTimeout(() => resolve(passthrough), timeout || 0)))),

    cancelable = promise => {
        let hasCanceled = false

        return {
            promise: (
                new Promise((resolve, reject) => (
                    Promise.resolve(promise)
                    .then(result => (!hasCanceled && resolve(result)))
                    .catch(error => (!hasCanceled && reject(error)))))),
            cancel: () => hasCanceled = true
        }
    },

    commonErrors = error => {
        const
            status = error?.response?.status,
            isSpammy = error?.message === 'Too many requests',
            isUnreachable = error?.message === 'Network Error',
            isInvalid = error?.message === 'invalid-requirements'

        return (
            status ?
                (status !== 403 && status !== 404) :
                (isInvalid || isUnreachable || isSpammy))
    },

    persistent = (fn, retry) => {
        const
            wait = backoff(),
            wrapped = () => (
                fn()
                .catch(error => (
                    (!retry ? commonErrors(error) : retry(error)) ?
                        delay(wait.get()).then(wrapped) :
                        Promise.reject(error))))

        return wrapped()
    }

export {
    cancelable,
    delay,
    isPromise,
    persistent,
    series
}
