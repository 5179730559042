import React, {forwardRef, createElement, useEffect} from 'react'
import PropTypes from 'prop-types'
import {keys} from '@republic/foundation/lang/object'
import {createComponent} from '@dash/core/services/component'
import {omit} from '../core/services/object'
import {create} from './utils/style-manager'
import {color, type as colorType} from './utils/styles/background-color'
import {border, type as borderType} from './utils/styles/border'
import {radius, type as radiusType} from './utils/styles/border-radius'
import {shadow, type as shadowType} from './utils/styles/box-shadow'
import {cursor, type as cursorType} from './utils/styles/cursor'
import {dimensions, type as dimensionsType} from './utils/styles/dimensions'
import {display, type as displayType} from './utils/styles/display'
import {margin, type as marginType} from './utils/styles/margin'
import {opacity, type as opacityType} from './utils/styles/opacity'
import {overflow, type as overflowType} from './utils/styles/overflow'
import {padding, type as paddingType} from './utils/styles/padding'
import {position, type as positionType} from './utils/styles/position'
import {positions, type as positionsType} from './utils/styles/positions'
import {transition, type as transitionType} from './utils/styles/transition'
import {layer, type as layerType} from './utils/styles/z-index'

const
    types = {
        className: PropTypes.string,
        tag: (
            PropTypes.oneOfType([
                PropTypes.string,
                PropTypes.node,
                PropTypes.func,
                PropTypes.element,
                PropTypes.elementType
            ])),
        children: PropTypes.node,

        margin: marginType,
        padding: paddingType,

        width: dimensionsType,
        height: dimensionsType,
        maxWidth: dimensionsType,
        maxHeight: dimensionsType,
        minWidth: dimensionsType,
        minHeight: dimensionsType,

        layer: layerType,
        position: positionType,
        positions: positionsType,

        border: borderType,
        color: colorType,
        cursor: cursorType,
        display: displayType,
        opacity: opacityType,
        overflow: overflowType,
        radius: radiusType,
        shadow: shadowType,
        transition: transitionType
    },

    Particle = (
        createComponent(
            'Particle',
            {
                propTypes: types
            },
            ({forwardRef, children, tag, ...props}) => {
                const
                    {destroy, hash, style} = (
                        create(
                            'bits-particle',
                            props,
                            {
                                ...margin(props.margin),
                                ...padding(props.padding),

                                ...{boxSizing: 'border-box'},
                                ...dimensions('width', props.width),
                                ...dimensions('height', props.height),
                                ...dimensions('maxWidth', props.maxWidth),
                                ...dimensions('maxHeight', props.maxHeight),
                                ...dimensions('minWidth', props.minWidth),
                                ...dimensions('minHeight', props.minHeight),

                                ...layer(props.layer),
                                ...position(props.position),
                                ...positions(props.positions),

                                ...border(props.border),
                                ...color(props.color),
                                ...cursor(props.cursor),
                                ...display(props.display),
                                ...opacity(props.opacity),
                                ...overflow(props.overflow),
                                ...radius(props.radius),
                                ...shadow(props.shadow),
                                ...transition(props.transition)
                            }))

                useEffect(
                    () => () => destroy(hash),
                    [hash])

                return (
                    createElement(
                        tag || 'div',
                        {
                            ref: forwardRef,
                            ...omit(props, keys(types)),
                            ...style,
                        },
                        children))
            }))

export default forwardRef((props, ref) => (
    <Particle forwardRef={ref} {...props} />
))
