import PropTypes from 'prop-types'
import {isValue} from '../is'

const
    type = (
        PropTypes.oneOfType([PropTypes.bool, PropTypes.string])),

    cursor = cursor => (
        isValue(cursor) ?
            {cursor} :
            {})

export {cursor, type}
