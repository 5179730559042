import React, {useEffect} from 'react'
import PropTypes from 'prop-types'
import {keys} from '@republic/foundation/lang/object'
import {createComponent} from '@dash/core/services/component'
import {omit} from '../core/services/object'
import Particle from './Particle'
import {create} from './utils/style-manager'
import {color, type as colorType} from './utils/styles/color'
import {decoration, type as decorationType} from './utils/styles/text-decoration'
import {size, type as sizeType} from './utils/styles/font-size'
import {weight, type as weightType} from './utils/styles/font-weight'
import {height, type as heightType} from './utils/styles/line-height'
import {align, type as alignType} from './utils/styles/text-align'
import {transform, type as transformType} from './utils/styles/text-transform'

const
    types = {
        className: PropTypes.string,
        tag: (
            PropTypes.oneOfType([
                PropTypes.string,
                PropTypes.node,
                PropTypes.func
            ])),
        align: alignType,
        color: colorType,
        decoration: decorationType,
        leading: heightType,
        size: sizeType,
        transform: transformType,
        weight: weightType
    },

    Type = (
        createComponent(
            'Type',
            {
                propTypes: types
            },
            props => {
                const
                    {destroy, hash, style} = (
                        create(
                            'bits-type',
                            props,
                            {
                                ...weight(props.weight),
                                ...align(props.align),
                                ...color(props.color),
                                ...decoration(props.decoration),
                                ...height(props.leading),
                                ...size(props.size),
                                ...transform(props.transform)
                            }))

                useEffect(
                    () => () => destroy(hash),
                    [hash])

                return (
                    <Particle
                        tag={props.tag || 'div'}
                        {...omit(props, keys(types))}
                        {...style} />
                )
            }))

export default Type
