import PropTypes from 'prop-types'
import {isString} from '@republic/foundation/lang/is'

const
    type = PropTypes.oneOf([
        'inline',
        'block',
        'inline-block',
        'flex',
        'inline-flex',
        'table',
        'table-row',
        'table-cell'
    ]),

    display = value => (
        isString(value) ?
            {display: value} :
            {})

export {display, type}
