import React, {useEffect, forwardRef} from 'react'
import PropTypes from 'prop-types'
import {keys} from '@republic/foundation/lang/object'
import {createComponent} from '@dash/core/services/component'
import {omit} from '../core/services/object'
import Particle from './Particle'
import {create} from './utils/style-manager'
import {direction} from './utils/styles/flex-direction'
import {wrap, type as wrapType} from './utils/styles/flex-wrap'
import {justify, type as justifyType} from './utils/styles/justify-content'
import {align, type as alignType} from './utils/styles/align-items'
import {flex, type as flexType} from './utils/styles/flex'

const
    types = {
        className: PropTypes.string,
        row: PropTypes.bool,
        inline: PropTypes.bool,
        reverse: PropTypes.bool,
        wrap: wrapType,
        justify: justifyType,
        align: alignType,
        flex: flexType
    },

    Layout = (
        createComponent(
            'Layout',
            {
                propTypes: types
            },
            ({forwardRef, inline, ...props}) => {
                const
                    {destroy, hash, style} = (
                        create(
                            'bits-layout',
                            props,
                            {
                                ...direction(props.row, props.reverse),
                                ...wrap(props.wrap),
                                ...justify(props.justify),
                                ...align(props.align),
                                ...flex(props.flex)
                            }))

                useEffect(
                    () => () => destroy(hash),
                    [hash])

                return (
                    <Particle
                        ref={forwardRef}
                        display={inline ? 'inline-flex' : 'flex'}
                        {...omit(props, keys(types))}
                        {...style} />
                )
            }))

export default forwardRef((props, ref) => (
    <Layout forwardRef={ref} {...props} />
))
