import {stringOrBool} from '../css'
import {isValue} from '../is'

const
    type = stringOrBool,

    shadow = value => (
        isValue(value) ?
            {
                boxShadow: (
                    value === true ?
                        '0 1px 2px rgba(0,0,0,0.25)' :
                        value)
            } :
            {})

export {shadow, type}
