import {isFunction} from '@republic/foundation/lang/is'

const
    createComponent = (
        (name,
        {
            propTypes = {},
            defaultProps = {}
        } = {},
        definition) => (

        Object.assign(
            isFunction(definition) ? definition : () => definition,
            {
                displayName: name,
                propTypes,
                defaultProps
            })))

export {
    createComponent
}