import PropTypes from 'prop-types'
import {isValue} from '../is'

const
    type = PropTypes.oneOf(['line-through', 'none', 'underline']),

    decoration = value => (
        isValue(value) ?
            {textDecoration: value} :
            {})

export {decoration, type}
