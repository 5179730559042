import PropTypes from 'prop-types'

const
    type = PropTypes.bool,

    wrap = wrapped => (
        wrapped ?
            {flexWrap: 'wrap'} :
            {})

export {wrap, type}
